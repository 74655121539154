export const backgroundImage = require("../assets/backgroundImage.png");
export const addaxLogo = require("../assets/addaxLogo.png");
export const hamburgerIcon = require("../assets/hamburgerIcon.png");
export const arrow = require("../assets/arrow.png");
export const lock = require("../assets/lock.png");
export const screenshot1 = require("../assets/screenshot1.png");
export const screenshot2 = require("../assets/screenshot2.png");
export const cursor = require("../assets/cursor.png");

export const blueBackgroundImage = require("../assets/blueBackgroundImage.png");
export const icon1 = require("../assets/icon1.png");
export const icon2 = require("../assets/icon2.png");
export const icon3 = require("../assets/icon3.png");
export const icon4 = require("../assets/icon4.png");

export const footerBackground = require("../assets/footerBackground.png");
export const addaxLogoBlack = require("../assets/addaxLogoBlack.png");
//images for LandingPageCenter component

export const img1 = require("../assets/df7d93cf36c0d997205e8230f9b00f8a55ec7b72.png");
//need asset to change img1 to marketplace image
export const book = require("../assets/image_book.png");
export const img2 = require("../assets/7a6ace06c2b13f748003de8b12aeeced8a52d710.png");

export const img3a = require("../assets/6b89ae522d97521ad6eb59bda1e1e9f450c81de8.png");
export const img3b = require("../assets/679c38d47d72d343550ec6068f279b02de2c2415.png");
export const img4 = require("../assets/5f74fa05e68aa48bf1f345586bb43d8dbc9e299b.png");

//images for comparison table
// need assets
export const bigFirm = require("../assets/bigFirm.png");
export const addax = require("../assets/2a229addd4189b037517da4323e2734a0fd336a4.png");
export const addaxLogoTable = require("../assets/addaxLogoTable.png");
export const bigTech = require("../assets/bigTech.png");
export const dashboardImages = require("../assets/dashboardImages.png");

export const Marketplace = require("../assets/Marketplace.png");
export const Dossiers = require("../assets/Dossiers.png");
export const Events = require("../assets/Events.png");
