import React from 'react';
import {
  Grid,
  Container,
  Typography
} from "@material-ui/core";
import {
  logoAddax
} from "./assets";
import SplashscreenController from "./SplashscreenController";
import { withStyles } from "@material-ui/core/styles";
// Customizable Area End

class Splashscreen extends SplashscreenController {
  
  render() {
    // Customizable Area Start
    const { classes }: any = this.props;
    return (
      <div style={webStyle.container}>
        <Container maxWidth="lg">
          <Grid container>
            <Grid item xs={12} sm={12} md={12}
              style={webStyle.innerContainer}
            >
              <img
                className={classes.logo}
                style={webStyle.logo}
                src={logoAddax}
                alt="logo"
                height="258"
                width="424"
              />
              <Typography
                className={classes.betaText}
                style={webStyle.betaText}
                variant="h3"
                component="div"
              >
                Beta
              </Typography>
            </Grid>
          </Grid>
        </Container>
      </div>
    );
    // Customizable Area End
  }
}

// Customizable Area Start
const styles = (theme: any) => ({
  logo: {
    [theme.breakpoints.down('sm')]: {
      height: '180px',
      width: '100px',
    },
    [theme.breakpoints.down('xs')]: {
      height: '130px',
      width: '70px',
    }
  },
  betaText: {
    [theme.breakpoints.down('sm')]: {
      fontSize: '35px',
    },
    [theme.breakpoints.down('xs')]: {
      fontSize: '25px',
    }
  }
});

const webStyle = {
  container: {
    height: '100%',
    backgroundColor: '#341f7c'
  },
  innerContainer: {
    position: 'absolute' as 'absolute',
    left: '50%',
    top: '50%',
    transform: 'translate(-50%, -50%)'
  },
  logo: {
    width: "100%"
  },
  betaText: {
    color: 'white',
    marginLeft: '-36%',
    marginTop: "-12%",
  },
};

export default withStyles(styles)(Splashscreen);
// Customizable Area End