import React from "react";
// Customizable Area Start
import {
  Grid,
  Box,
  Typography,
  Container,
  CircularProgress
} from "@material-ui/core";
import {
  hamburgerIcon,
  addaxLogo,
  profileIcon,
  expandIcon
} from "./assets";
import {
  List,
  ListItem,
  ListItemText,
  Collapse,
  Drawer
 } from '@material-ui/core';
import CategoriessubcategoriesController from "./CategoriessubcategoriesController";
import { withStyles } from "@material-ui/core/styles";
import SnackbarAlert from "../../../components/src/SnackbarAlert.web";

const StyledListItemText = withStyles({
  root: {
    fontFamily: 'AirbnbCerealApp-Bold',
    '& .MuiTypography-root': {
      fontWeight: 'bold',
      fontSize: "16px",
    }
  }
})(ListItemText);

const StyledListItem = withStyles({
  root: {
    fontFamily: 'AirbnbCerealApp-Bold',
    '& .MuiTypography-root': {
      padding: "3px 15px",
      fontSize: "14px",
      width: "86%",
      borderRadius: "8px",
      color: "#341f7c",
      background: "rgba(52,31,124,0.04)",
      fontWeight: "bold",
    }
  }
})(ListItemText);
// Customizable Area End

export default class CategoriesSideBar extends CategoriessubcategoriesController {

  // Customizable Area Start
  navigate = async () => {
    sessionStorage.removeItem("article-id");
    sessionStorage.removeItem("marketplace-id");
    sessionStorage.removeItem("event-id");
    sessionStorage.removeItem("dossier-id");
    this.props.navigation.navigate('RecentArticles');
    window.location.reload();
  }
  // Customizable Area End

  render() {
    // Customizable Area 
    const { navigation } = this.props;
    return (
      <Grid container
        style={{ 
          position: 'fixed' as 'fixed',
          zIndex: 99
        }}
      >
        <Grid item xs={12} sm={12} md={12} style={webStyle.mainHeader}>
          <Container maxWidth="lg" style={{maxWidth: "80%"}}>
            <Box style={webStyle.headerIcons}>
              <img
                style={webStyle.hamburgerIcon}
                src={hamburgerIcon}
                alt="Icon"
                onClick={this.props?.onClosed}
              />
              <Typography
                style={{color: '#341f7c'}}
                variant="body2"
                component="div"
              >
                Beta&nbsp;&nbsp;
              <img
                style={webStyle.addaxLogo}
                src={addaxLogo}
                alt="Logo"
              />
              </Typography>
              <img
                style={webStyle.profileIcon}
                src={profileIcon}
                alt="Profile Icon"
                onClick={() => {
                  navigation.navigate("UserProfileBasicBlock");
                }}
              />
            </Box>
          </Container>
        </Grid>
        <Grid item xs={12} sm={12} md={12}>
          <Drawer
            PaperProps={{
              style: { width: this.props?.drawerWidth, boxShadow: "1.5px 0px 30px rgba(0,0,0,0.14)" }
            }}
            style={webStyle.drawer}
            variant="persistent"
            open={this.props?.openDrawer}
            onClose={this.props?.onClosed}
          >
            <div style={{width: `${this.props?.drawerWidth}px`}}>
              <Box style={webStyle.drawerIcons}>
                <Typography
                  style={{color: '#341f7c'}}
                  variant="body2"
                  component="div"
                >
                  Beta&nbsp;&nbsp;
                <img
                  style={{
                    width: '70.26px',
                    height: '48px',
                    filter: "opacity(0.7) drop-shadow(0px 0px 0px blue)"
                  }}
                  src={addaxLogo}
                  alt="Logo"
                />
                </Typography>
                <img
                  style={{
                    width: '28px',
                    height: '28px',
                    cursor: 'pointer',
                  }}
                  src={hamburgerIcon}
                  alt=""
                  onClick={this.props.onClosed}
                />
              </Box>

              <List style={{marginTop: '20px', cursor: 'pointer'}}>
                {/* Markets */}
                <ListItem
                  style={webStyle.drawerHeader}
                  onClick={() => this.setState({ collapseOpen1: !this.state?.collapseOpen1 })}
                >
                  <StyledListItemText primary="Markets" />
                  <img src={expandIcon} alt="" width="22.99" height="22" />
                </ListItem>
                <Collapse in={this.state?.collapseOpen1} timeout="auto" unmountOnExit>
                  <List component="div" disablePadding style={webStyle.list}>
                    <ListItem style={webStyle.listItem}>
                      <StyledListItem primary="Marketplace" onClick={() => {
                        navigation.navigate("MarketPlace");
                        this.setState({ openDrawer: !this.state?.openDrawer });
                        sessionStorage.removeItem("article-id");
                        sessionStorage.removeItem("marketplace-id");
                        sessionStorage.removeItem("event-id");
                        sessionStorage.removeItem("dossier-id");
                      }} />
                    </ListItem>
                  </List>
                  <List component="div" disablePadding style={webStyle.list} key="">
                    <ListItem style={webStyle.listItem}>
                      <StyledListItem primary="Market Maps" onClick={() => this.navigate()} />
                    </ListItem>
                  </List>
                  <List component="div" disablePadding style={webStyle.list} key="Market Guides">
                    <ListItem style={webStyle.listItem}>
                      <StyledListItem primary="Market Guides" onClick={() => this.navigate()} />
                    </ListItem>
                  </List>
                </Collapse>
                <hr style={webStyle.hrTag} />

                {/* Intelligence */}

                <ListItem
                  style={webStyle.drawerHeader}
                  onClick={() => this.setState({ collapseOpen2: !this.state?.collapseOpen2 })}
                >
                  <StyledListItemText primary="Intelligence" />
                  <img src={expandIcon} alt="" width="22.99" height="22" />
                </ListItem>
                <Collapse in={this.state?.collapseOpen2} timeout="auto" unmountOnExit>
                  <List component="div" disablePadding style={webStyle.list}>
                    <ListItem style={webStyle.listItem}>
                      <StyledListItem primary="Dossiers" onClick={() => {
                        navigation.navigate("Dossiers");
                        this.setState({ openDrawer: !this.state?.openDrawer });
                        sessionStorage.removeItem("article-id");
                        sessionStorage.removeItem("marketplace-id");
                        sessionStorage.removeItem("event-id");
                        sessionStorage.removeItem("dossier-id");
                      }} />
                    </ListItem>
                  </List>
                  <List component="div" disablePadding style={webStyle.list}>
                    <ListItem style={webStyle.listItem}>
                      <StyledListItem primary="Events" onClick={() => {
                        navigation.navigate("Events");
                        this.setState({ openDrawer: !this.state?.openDrawer });
                        sessionStorage.removeItem("article-id");
                        sessionStorage.removeItem("marketplace-id");
                        sessionStorage.removeItem("event-id");
                        sessionStorage.removeItem("dossier-id");
                      }} />
                    </ListItem>
                  </List>
                  <List component="div" disablePadding style={webStyle.list} key="Case Studies">
                    <ListItem style={webStyle.listItem}>
                      <StyledListItem primary="Case Studies" onClick={() => this.navigate()} />
                    </ListItem>
                  </List>
                </Collapse>
                <hr style={webStyle.hrTag} />

                {/* Experts */}

                <ListItem
                  style={webStyle.drawerHeader}
                  onClick={() => this.setState({ collapseOpen3: !this.state?.collapseOpen3 })}
                >
                  <StyledListItemText primary="Experts" />
                  <img src={expandIcon} alt="" width="22.99" height="22" />
                </ListItem>
                <Collapse in={this.state?.collapseOpen3} timeout="auto" unmountOnExit>
                  <List component="div" disablePadding style={webStyle.list} key="Management">
                    <ListItem style={webStyle.listItem}>
                      <StyledListItem primary="Management" onClick={() => this.navigate()} />
                    </ListItem>
                  </List>
                  <List component="div" disablePadding style={webStyle.list} key="Roadmaps">
                    <ListItem style={webStyle.listItem}>
                      <StyledListItem primary="Roadmaps" onClick={() => this.navigate()} />
                    </ListItem>
                  </List>
                  <List component="div" disablePadding style={webStyle.list} key="Executive Communications">
                    <ListItem style={webStyle.listItem}>
                      <StyledListItem primary="Executive Communications" onClick={() => this.navigate()} />
                    </ListItem>
                  </List>
                </Collapse>
                <hr style={webStyle.hrTag} />

                {/* Tools and Templates */}

                <ListItem
                  style={webStyle.drawerHeader}
                  onClick={() => this.setState({ collapseOpen4: !this.state?.collapseOpen4 })}
                >
                  <StyledListItemText primary="Tools and Templates" />
                  <img src={expandIcon} alt="" width="22.99" height="22" />
                </ListItem>
                <Collapse in={this.state?.collapseOpen4} timeout="auto" unmountOnExit>
                  <List component="div" disablePadding style={webStyle.list} key="Management Toolkit">
                    <ListItem style={webStyle.listItem}>
                      <StyledListItem primary="Management Toolkit" onClick={() => this.navigate()} />
                    </ListItem>
                  </List>
                  <List component="div" disablePadding style={webStyle.list} key="Communications">
                    <ListItem style={webStyle.listItem}>
                      <StyledListItem primary="Communications" onClick={() => this.navigate()} />
                    </ListItem>
                  </List>
                  <List component="div" disablePadding style={webStyle.list} key="Procurement">
                    <ListItem style={webStyle.listItem}>
                      <StyledListItem primary="Procurement" onClick={() => this.navigate()} />
                    </ListItem>
                  </List>
                </Collapse>
                <hr style={webStyle.hrTag} />
              </List>
            </div>
          </Drawer>

          {this.state?.tokenExpired === 'Token has Expired' ?
            <SnackbarAlert
              openSnackbar={this.state?.openSnackbar}
              handleCloseSnackbar={this.handleCloseSnackbar}
            />
            : null
          }
        </Grid>
      </Grid>
    );
    // Customizable Area End
  }
}

// Customizable Area Start
const webStyle = {
  mainHeader: {
    height: '72px',
    background: '#ffffff',
    boxShadow: '2px 2px 15px rgba(52,31,124,0.08)',
  },
  drawer: {
    flexShrink: 0,
    boxShadow: "1.5px 0px 30px rgba(0,0,0,0.14)"
  },
  drawerIcons: {
    display: 'flex',
    flexDirection: 'row' as 'row',
    justifyContent: 'space-around',
    alignItems: "center",
    marginTop : "15px",
    marginLeft: '25%',
  },
  drawerHeader: {
    marginLeft: '15px',
    marginRight: '15px',
    marginBottom:"10px",
    width: '315px',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-end',
    paddingLeft: "25px",
    paddingRight: "30px",
  },
  headerIcons: {
    height: '100%',
    display: 'flex',
    flexDirection: 'row' as 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
  },
  hamburgerIcon: {
    left: '178px',
    width: '28px',
    height: '28px',
    cursor: 'pointer',
  },
  addaxLogo: {
    left: '685px',
    width: '70.26px',
    height: '48px',
    filter: "opacity(0.7) drop-shadow(0px 0px 0px blue)"
  },
  profileIcon: {
    left: '1238px',
    width: '40px',
    height: '40px',
    cursor: 'pointer',
  },
  list: {
    marginBottom: "10px",
    marginLeft: 10
  },
  listItem: {
    height: "25px",
    cursor: 'pointer'
  },
  hrTag: {
    width: '80%',
    border: "0.5px solid #341f7c",
    opacity: "0.08475167"
  }
};
// Customizable Area End