import React from "react";
// Customizable Area Start
import {
  Grid,
  Box,
  Button,
  Typography,
  Container,
  Hidden,
  Drawer,
  List,
  ListItem,
  ListItemText,
  Divider,
  withStyles
} from "@material-ui/core";
import MenuIcon from "@material-ui/icons/Menu";
import {
  backgroundImage,
  addaxLogo,
  lock,
  arrow,
  cursor,
  screenshot1,
  screenshot2,
} from "./assets";
import LandingPageController from "./LandingPageController";
import LandingPageServicesPortfolio from "./LandingPageServicesPortfolio.web";
import LandingPageCenter from "./LandingPageCenter.web";
import LandingPageFooter from "./LandingPageFooter.web";
// Customizable Area End

class LandingPage extends LandingPageController {
  
  render() {
    // Customizable Area Start
    const { navigation } = this.props;
    const { classes }: any = this.props;
    return (
      <>
        <div style={webStyle.container} className={classes.container}>
          <Container maxWidth={false} style={{maxWidth: "81%"}}>
            <Grid container style={webStyle.mainContainer}>
              <Grid item style={webStyle.addaxLogoContainer}>
                <Typography
                  className={classes.betaText}
                  style={webStyle.betaText}
                >
                  Beta
                </Typography>
                <img
                  className={classes.addaxLogo}
                  style={webStyle.addaxLogo}
                  src={addaxLogo}
                  alt="Logo"
                />
              </Grid>
              <Hidden smDown>
                <Grid item style={webStyle.textContents}>
                  <Box style={webStyle.cursorIconStyle}>
                    <Typography variant="body2" style={webStyle.homeText}>
                      <a href="#home" style={webStyle.cursorStyle}
                        onClick={() => this.setState({
                          homeActive: true,
                          toolsActive: false,
                          companyActive: false,
                          guidanceActive: false,
                          servicesActive: false,
                          insightsActive: false,
                          contactActive: false,
                          pricingActive: false,
                        })}
                      >
                        Home
                      </a>
                    </Typography>
                    {this.state?.homeActive ? <img style={{marginTop: "-8px", height: "0.6vw", width: "2.5vw"}} src={cursor} alt="" /> : null}
                  </Box>
                  <Box style={webStyle.cursorIconStyle}>
                    <Typography variant="body2" style={webStyle.servicesContact}>
                      <a href="#services" style={webStyle.cursorStyle}
                        onClick={() => this.setState({
                          servicesActive: true,
                          toolsActive: false,
                          companyActive: false,
                          guidanceActive: false,
                          insightsActive: false,
                          homeActive: false,
                          contactActive: false,
                          pricingActive: false,
                        })}
                      >
                        Services
                      </a>
                    </Typography>
                    {this.state?.servicesActive ? <img style={{marginTop: "-8px", height: "auto", width: "4vw"}} src={cursor} alt="" /> : null}
                  </Box>
                  <Box style={webStyle.cursorIconStyle}>
                    <Typography variant="body2" style={webStyle.servicesContact}>
                      <a href="#" style={webStyle.cursorStyle}
                        onClick={() => this.setState({
                          pricingActive: true,
                          toolsActive: false,
                          companyActive: false,
                          guidanceActive: false,
                          insightsActive: false,
                          servicesActive: false,
                          homeActive: false,
                          contactActive: false
                        })}
                      >
                        Pricing
                      </a>
                    </Typography>
                    {this.state?.pricingActive ? <img style={{marginTop: "-8px", height: "auto", width: "3vw"}} src={cursor} alt="" /> : null}
                  </Box>
                  <Box style={webStyle.cursorIconStyle}>
                    <Typography variant="body2" style={webStyle.servicesContact}>
                      <a href="#insights" style={webStyle.cursorStyle}
                        onClick={() => this.setState({
                          insightsActive: true,
                          toolsActive: false,
                          companyActive: false,
                          pricingActive: false,
                          guidanceActive: false,
                          servicesActive: false,
                          homeActive: false,
                          contactActive: false
                        })}
                      >
                        Insights
                      </a>
                    </Typography>
                    {this.state?.insightsActive ? <img style={{marginTop: "-8px", height: "auto", width: "3.5vw"}} src={cursor} alt="" /> : null}
                  </Box>
                  <Box style={webStyle.cursorIconStyle}>
                    <Typography variant="body2" style={webStyle.servicesContact}>
                      <a href="#" style={webStyle.cursorStyle}
                        onClick={() => this.setState({
                          guidanceActive: true,
                          toolsActive: false,
                          companyActive: false,
                          insightsActive: false,
                          pricingActive: false, 
                          servicesActive: false,
                          homeActive: false,
                          contactActive: false
                        })}
                      >
                        Guidance
                      </a>
                    </Typography>
                    {this.state?.guidanceActive ? <img style={{marginTop: "-8px", height: "auto", width: "4vw"}} src={cursor} alt="" /> : null}
                  </Box>
                  <Box style={webStyle.cursorIconStyle}>
                    <Typography variant="body2" style={webStyle.servicesContact}>
                      <a href="#" style={webStyle.cursorStyle}
                        onClick={() => this.setState({
                          companyActive: true,
                          toolsActive: false,
                          guidanceActive: false,
                          insightsActive: false,
                          pricingActive: false, 
                          servicesActive: false,
                          homeActive: false,
                          contactActive: false
                        })}
                      >
                        Company
                      </a>
                    </Typography>
                    {this.state?.companyActive ? <img style={{marginTop: "-8px", height: "auto", width: "4vw"}} src={cursor} alt="" /> : null}
                  </Box>
                  <Box style={webStyle.cursorIconStyle}>
                    <Typography variant="body2" style={webStyle.servicesContact}>
                      <a href="#" style={webStyle.cursorStyle}
                        onClick={() => this.setState({
                          toolsActive: true,
                          companyActive: false,
                          guidanceActive: false,
                          insightsActive: false,
                          pricingActive: false, 
                          servicesActive: false,
                          homeActive: false,
                          contactActive: false
                        })}
                      >
                        Tools
                      </a>
                    </Typography>
                    {this.state?.toolsActive ? <img style={{marginTop: "-8px", height: "0.6vw", width: "2.3vw"}} src={cursor} alt="" /> : null}
                  </Box>
                  <Box style={webStyle.cursorIconStyle}>
                    <Typography variant="body2" style={webStyle.servicesContact}>
                      <a href="#contact" style={webStyle.cursorStyle}
                        onClick={() => this.setState({
                          contactActive: true,
                          toolsActive: false,
                          companyActive: false,
                          guidanceActive: false,
                          servicesActive: false,
                          homeActive: false,
                          insightsActive: false,
                          pricingActive: false,
                        })}
                      >
                        Contact
                      </a>
                    </Typography>
                    {this.state?.contactActive ? <img style={{marginTop: "-8px", height: "auto", width: "3.5vw"}} src={cursor} alt="" /> : null}
                  </Box>
                </Grid>
                <Grid item style={webStyle.loginButton}>
                  <img style={{marginRight: 8, height: "auto", width: "0.8vw"}} src={lock} alt="" />
                  <Typography
                    style={{cursor: 'pointer', fontSize: "1.2vw"}}
                    // onClick={() => navigation.navigate("EmailAccountLoginBlock")}
                  >
                    Login
                  </Typography>
                  <Button
                    style={webStyle.button}
                    variant="contained"
                  >
                    <a
                      style={webStyle.clientButton}
                      href="mailto:support@addax.ai">
                      Become a client
                    </a>
                  </Button>
                </Grid>
              </Hidden>
              <Hidden mdUp>
                <Box sx={{ flexGrow: 1}} />
                <MenuIcon
                  style={webStyle.menuIcon}
                  onClick={() => this.setState({
                    openDrawer: !this.state?.openDrawer
                  })}  
                />
              </Hidden>
            </Grid>

            {/* Drawer Content */}

            <Drawer
              PaperProps={{ style: { width: 250 } }}
              open={this.state?.openDrawer}
              onClose={() => this.setState({
                openDrawer: !this.state?.openDrawer
              })}
            >
              <Grid item>
                <List
                  style={{marginLeft: 20}}
                  onClick={() => this.setState({
                    openDrawer: !this.state?.openDrawer
                  })}
                >
                  <ListItem button>
                    <a href="#home" style={webStyle.listItemTexts}>
                      <ListItemText primary="Home" />
                    </a>
                  </ListItem>
                  <ListItem button>
                    <a href="#services" style={webStyle.listItemTexts}>
                      <ListItemText primary="Services" />
                    </a>
                  </ListItem>
                  <ListItem button>                    
                    <ListItemText primary="Pricing" />
                  </ListItem>
                  <ListItem button>
                    <a href="#insights" style={webStyle.listItemTexts}>   
                      <ListItemText primary="Insights" />
                    </a>
                  </ListItem>
                  <ListItem button>            
                    <ListItemText primary="Guidance" />
                  </ListItem>
                  <ListItem button>
                    <ListItemText primary="Company" />
                  </ListItem>
                  <ListItem button>
                    <ListItemText primary="Tools" />
                  </ListItem>
                  <ListItem button>
                    <a href="#contact" style={webStyle.listItemTexts}>
                      <ListItemText primary="Contact" />
                    </a>
                  </ListItem>
                  <Divider style={webStyle.divider} />
                  <ListItem
                    button
                    // onClick={() => navigation.navigate("EmailAccountLoginBlock")}
                  >
                    <ListItemText primary="Login" />
                  </ListItem>
                  <ListItem
                    button
                  >
                    <a
                      style={webStyle.clientButtonDawer}
                      href="mailto:support@addax.ai">
                      <ListItemText primary="Become a Client" />
                    </a>
                  </ListItem>
                </List>
              </Grid>
            </Drawer>

            {/* Left & Right content */}

            <Grid container spacing={8}>
              <Grid item xs={12} sm={6} md={6} style={webStyle.leftContent}>
                <Box style={webStyle.welcomeArrowIcon}>
                  <img
                    style={webStyle.welcomeArrow}
                    src={arrow}
                    alt=""
                  />
                  <Typography
                    style={webStyle.welcomeText}
                    variant="subtitle1"
                  >
                    Welcome to Addax
                  </Typography>
                </Box>
                <Typography
                  style={webStyle.helpingText}
                  variant="h5"
                >
                  Helping you build trust
                  in your digital community
                </Typography>
                <Typography
                  style={webStyle.subTitle}
                  variant="body1"
                >
                  Addax Empowers Executives in high tech and government<br/>
                  mitigate the risks of disinformation with data, insight,<br/> 
                  and best practices.
                </Typography>
                <Button
                  style={webStyle.button2}
                  variant="contained"
                >
                  <a
                    style={webStyle.clientButton}
                    href="mailto:support@addax.ai">
                    Become a client
                  </a>
                </Button>
              </Grid>
              <Grid item xs={12} sm={6} md={6}>
                <img
                  className={classes.screenshot1}
                  style={webStyle.screenshot1}
                  src={screenshot1} alt="Image"
                />
                <img
                  className={classes.screenshot2}
                  style={webStyle.screenshot2}
                  src={screenshot2} alt="Image"
                />
              </Grid>
            </Grid>
          </Container>
        </div>
        <LandingPageCenter {...this.props} />
        <LandingPageServicesPortfolio {...this.props} />
        <LandingPageFooter {...this.props} />
      </>
    );
    // Customizable Area End
  }
}

// Customizable Area Start
const styles = (theme: any) => ({
  container: {
    [theme.breakpoints.between("xs", "sm")]: {
      paddingBottom: "200px",
    },
    [theme.breakpoints.down("md")]: {
      paddingBottom: "200px !important",
    },
    [theme.breakpoints.up("lg")]: {
      paddingBottom: "260px !important",
    },
    [theme.breakpoints.only("xl")]: {
      paddingBottom: "400px !important",
    },
  },
  screenshot1: {
    [theme.breakpoints.down("md")]: {
      top: "80% !important",
      left: "-10% !important",
    },
    [theme.breakpoints.up("lg")]: {
      top: "86% !important",
    },
    [theme.breakpoints.only("xl")]: {
      top: "96% !important",
    },
  },
  screenshot2: {
    [theme.breakpoints.down("md")]: {
      top: "59% !important",
    },
    [theme.breakpoints.up("lg")]: {
      top: "65% !important",
    },
    [theme.breakpoints.only("xl")]: {
      top: "73% !important",
    },
  }
});

const webStyle = {
  container: {
    display: "flex",
    width: "100%",
    paddingBottom: "200px",
    backgroundImage: `url(${backgroundImage})`,
    backgroundSize: '100% auto',
    backgroundPosition: 'center top',
    backgroundRepeat: 'no-repeat',
  },
  mainContainer: {
    marginTop: 25,
  },
  addaxLogoContainer: {
    display: 'flex',
    flexDirection: 'row' as 'row',
    justifyContent: 'flex-start',
    alignItems: 'baseline',
  },
  addaxLogo: {
    height: "auto",
    width: "6.5vw"
  },
  betaText: {
    color: "#fff",
    marginRight: "8px",
    fontSize: "0.9vw"
  },
  textContents: {
    display: 'flex',
    flexDirection: 'row' as 'row',
    justifyContent: 'center',
    alignItems: 'center',
    color: "#fff",
    marginLeft: "auto"
  },
  homeText: {
    padding: "8px",
    cursor: 'pointer'
  },
  servicesContact: {
    padding: "10px",
    cursor: 'pointer'
  },
  cursorIconStyle: {
    display: "flex",
    flexDirection: "column" as "column",
    justifyContent: "center",
    alignItems: "center"
  },
  cursorStyle: {
    textDecoration: "none",
    color: "#fff",
    fontSize: "1vw"
  },
  loginButton: {
    display: 'flex',
    flexDirection: 'row' as 'row',
    justifyContent: 'flex-end',
    alignItems: 'center',
    color: "#fff",
    marginLeft: "auto",
  },
  button: {
    backgroundColor: "#6449d1",
    color: "#fff",
    marginLeft: "20px",
    textTransform: "capitalize" as "capitalize",
    padding: '1.5vw',
    height: "43px",
    fontSize: "0.9vw"
  },
  divider: {
    width: "90%",
    margin: "10px 0px"
  },
  menuIcon: {
    color: "#fff",
    marginTop: 20,
    cursor: 'pointer'
  },
  listItemTexts: {
    textDecoration: "none",
    color: "black"
  },
  clientButtonDawer: {
    textDecoration: 'none',
    color: "black"
  },
  leftContent: {
    marginTop: "10%",
  },
  welcomeText: {
    color: "#989cb8",
    display: 'flex',
    flexDirection: 'column' as 'column',
    justifyContent: 'flex-end',
    alignItems: 'center',
    fontSize: "1vw",
    fontWeight: 500
  },
  welcomeArrowIcon: {
    display: 'flex',
    flexDirection: 'row' as 'row',
    justifyContent: 'flex-start',
    alignItems: 'center',
    color: "#989cb8",
  },
  welcomeArrow: {
    marginRight: 8,
    color: "#989cb8",
    height: "auto",
    width: "0.7vw"
  },
  helpingText: {
    color: "#ffffff",
    fontSize: "3vw",
    fontWeight: 500,
    marginTop: 20,
  },
  subTitle: {
    color: "#989cb8",
    fontSize: "1vw",
    fontWeight: 400,
    marginTop: 20
  },
  button2: {
    backgroundColor: "#6449d1",
    color: "#fff",
    height: "43px",
    textTransform: "capitalize" as "capitalize",
    padding: '1.5vw',
    marginTop: 30,
    fontSize: "0.9vw"
  },
  clientButton: {
    textDecoration: 'none',
    color: "#fff"
  },
  screenshot1: {
    position: "relative" as "relative",
    top: "91%",
    left: "-8%",
    height: "auto",
    width: "15vw"
  },
  screenshot2: {
    position: "relative" as "relative",
    top: "68%",
    left: "27%",
    height: "auto",
    width: "15vw"
  },
};

export default withStyles(styles)(LandingPage);
// Customizable Area End