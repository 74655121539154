import React, { Component } from 'react';
import { Snackbar } from "@material-ui/core";
import MuiAlert, { AlertProps } from '@material-ui/lab/Alert';
type MyProps = { openSnackbar: boolean; handleCloseSnackbar: any };

function Alert(props: AlertProps) {
    return <MuiAlert elevation={6} variant="filled" {...props} />;
}

export default class SnackbarAlert extends Component<MyProps, any> {
  constructor(props: any) {
    super(props);

  }

    render() {
        return (
            <div>
                <Snackbar open={this.props.openSnackbar} autoHideDuration={6000} onClose={this.props.handleCloseSnackbar}>
                    <Alert onClose={this.props.handleCloseSnackbar} severity="warning">
                        Session Expired. Please login again.
                    </Alert>
                </Snackbar>
            </div>
        );
    }

}
