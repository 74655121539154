// App.js - WEB
import React, { Component } from "react";
import { View } from "react-native";
import firebase from 'firebase'
import { connect } from 'react-firebase'

import WebRoutesGenerator from "../../components/src/NativeWebRouteWrapper";
import { ModalContainer } from "react-router-modal";
import HomeScreen from "../../components/src/HomeScreen";
import TopNav from "../../components/src/TopNav";

import InfoPage from '../../blocks/info-page/src/InfoPageBlock'
import AlertBlock from '../../blocks/alert/src/AlertBlock.web'
import SocialMediaAccountLoginScreen from "../../blocks/social-media-account-login/src/SocialMediaAccountLoginScreen";
import ServicespecificSettingsadmin from "../../blocks/ServicespecificSettingsadmin/src/ServicespecificSettingsadmin";
import OTPInputAuth from "../../blocks/otp-input-confirmation/src/OTPInputAuth";
import PeopleManagement2 from "../../blocks/PeopleManagement2/src/PeopleManagement2";
import NavigationMenu from "../../blocks/navigationmenu/src/NavigationMenu";
import ForgotPassword from "../../blocks/forgot-password/src/ForgotPassword";
import ForgotPasswordOTP from "../../blocks/forgot-password/src/ForgotPasswordOTP";
import NewPassword from "../../blocks/forgot-password/src/NewPassword";
import Airtable2 from "../../blocks/Airtable2/src/Airtable2";
import AdminConsole3 from "../../blocks/AdminConsole3/src/AdminConsole3";
import Settings5 from "../../blocks/Settings5/src/Settings5";
// import UserProfileBasicBlock from "../../blocks/user-profile-basic/src/UserProfileBasicBlock";
import Categoriessubcategories from "../../blocks/categoriessubcategories/src/Categoriessubcategories";
import ContentManagement from "../../blocks/ContentManagement/src/ContentManagement";
import CountryCodeSelector from "../../blocks/country-code-selector/src/CountryCodeSelector";
import PhoneNumberInput from "../../blocks/mobile-account-registration/src/PhoneNumberInput";
import AdditionalDetailForm from "../../blocks/mobile-account-registration/src/AdditionalDetailForm";
import SocialMediaAccountRegistrationScreen from "../../blocks/social-media-account-registration/src/SocialMediaAccountRegistrationScreen";
import EmailAccountRegistration from "../../blocks/email-account-registration/src/EmailAccountRegistration";
// import Splashscreen from "../../blocks/splashscreen/src/Splashscreen";
// import EmailAccountLoginBlock from "../../blocks/email-account-login/src/EmailAccountLoginBlock";
// import LandingPage from "../../blocks/landingpage/src/LandingPage";
import EmailNotifications from "../../blocks/EmailNotifications/src/EmailNotifications";

import Splashscreen from "../../blocks/splashscreen/src/Splashscreen.web";
import LandingPage from "../../blocks/landingpage/src/LandingPage.web";
import EmailAccountLoginBlock from "../../blocks/email-account-login/src/EmailAccountLoginBlock.web";
import EmailAccountForgotPassword from "../../blocks/email-account-login/src/EmailAccountForgotPassword.web";
import EmailAccountResetPassword from "../../blocks/email-account-login/src/EmailAccountResetPassword.web";
import EmailAccountTermsConditions from "../../blocks/email-account-login/src/EmailAccountTermsConditions.web";
import EmailAccountPrivacyPolicy from "../../blocks/email-account-login/src/EmailAccountPrivacyPolicy.web";
import RecentArticles from "../../blocks/categoriessubcategories/src/RecentArticles.web";
import ShowArticles from "../../blocks/categoriessubcategories/src/ShowArticles.web";
import MarketPlace from "../../blocks/categoriessubcategories/src/MarketPlace.web";
import ShowMarketPlace from "../../blocks/categoriessubcategories/src/ShowMarketPlace.web";
import Events from "../../blocks/categoriessubcategories/src/Events.web";
import ShowEvents from "../../blocks/categoriessubcategories/src/ShowEvents.web";
import Dossiers from "../../blocks/categoriessubcategories/src/Dossiers.web";
import ShowDossiers from "../../blocks/categoriessubcategories/src/ShowDossiers.web";
import UserProfileBasicBlock from "../../blocks/user-profile-basic/src/UserProfileBasicBlock.web";
import TermsConditions from "../../blocks/user-profile-basic/src/TermsConditions.web";


const routeMap = {
SocialMediaAccountLoginScreen:{
 component:SocialMediaAccountLoginScreen,
path:"/SocialMediaAccountLoginScreen"},
ServicespecificSettingsadmin:{
 component:ServicespecificSettingsadmin,
path:"/ServicespecificSettingsadmin"},
OTPInputAuth:{
 component:OTPInputAuth,
path:"/OTPInputAuth"},
PeopleManagement2:{
 component:PeopleManagement2,
path:"/PeopleManagement2"},
NavigationMenu:{
 component:NavigationMenu,
path:"/NavigationMenu"},
ForgotPassword:{
 component:ForgotPassword,
path:"/ForgotPassword"},
ForgotPasswordOTP:{
 component:ForgotPasswordOTP,
path:"/ForgotPasswordOTP"},
NewPassword:{
 component:NewPassword,
path:"/NewPassword"},
Airtable2:{
 component:Airtable2,
path:"/Airtable2"},
AdminConsole3:{
 component:AdminConsole3,
path:"/AdminConsole3"},
Settings5:{
 component:Settings5,
path:"/Settings5"},
// UserProfileBasicBlock:{
//  component:UserProfileBasicBlock,
// path:"/UserProfileBasicBlock"},
Categoriessubcategories:{
 component:Categoriessubcategories,
path:"/Categoriessubcategories"},
ContentManagement:{
 component:ContentManagement,
path:"/ContentManagement"},
CountryCodeSelector:{
 component:CountryCodeSelector,
path:"/CountryCodeSelector"},
PhoneNumberInput:{
 component:PhoneNumberInput,
path:"/PhoneNumberInput"},
AdditionalDetailForm:{
 component:AdditionalDetailForm,
path:"/AdditionalDetailForm"},
SocialMediaAccountRegistrationScreen:{
 component:SocialMediaAccountRegistrationScreen,
path:"/SocialMediaAccountRegistrationScreen"},
EmailAccountRegistration:{
 component:EmailAccountRegistration,
path:"/EmailAccountRegistration"},
// Splashscreen:{
//  component:Splashscreen,
// path:"/Splashscreen"},
// EmailAccountLoginBlock:{
//  component:EmailAccountLoginBlock,
// path:"/EmailAccountLoginBlock"},
// LandingPage:{
//  component:LandingPage,
// path:"/LandingPage"},
EmailNotifications:{
 component:EmailNotifications,
path:"/EmailNotifications"},

//web starts------
Splashscreen:{
component:Splashscreen,
path:"/Splashscreen"},
LandingPage:{
component:LandingPage,
path:"/LandingPage"},
EmailAccountLoginBlock:{
component:EmailAccountLoginBlock,
path:"/EmailAccountLoginBlock"},
EmailAccountForgotPassword:{
component:EmailAccountForgotPassword,
path:"/EmailAccountForgotPassword"},
EmailAccountResetPassword:{
component:EmailAccountResetPassword,
path:"/EmailAccountResetPassword"},
EmailAccountTermsConditions:{
component:EmailAccountTermsConditions,
path:"/EmailAccountTermsConditions"},
EmailAccountPrivacyPolicy:{
component:EmailAccountPrivacyPolicy,
path:"/EmailAccountPrivacyPolicy"},
RecentArticles:{
component:RecentArticles,
path:"/RecentArticles"},
ShowArticles:{
component:ShowArticles,
path:"/ShowArticles"},
MarketPlace:{
component:MarketPlace,
path:"/MarketPlace"},
ShowMarketPlace:{
component:ShowMarketPlace,
path:"/ShowMarketPlace"},
Events:{
component:Events,
path:"/Events"},
ShowEvents:{
component:ShowEvents,
path:"/ShowEvents"},
Dossiers:{
component:Dossiers,
path:"/Dossiers"},
ShowDossiers:{
component:ShowDossiers,
path:"/ShowDossiers"},
UserProfileBasicBlock:{
component:UserProfileBasicBlock,
path:"/UserProfileBasicBlock"},
TermsConditions:{
component:TermsConditions,
path:"/TermsConditions"},

  Home: {
    component: LandingPage,
    path: '/',
    exact: true
  },
  InfoPage: {
    component: InfoPage,
    path: '/InfoPage'
  },

  AlertWeb: {
    component: AlertBlock,
    path: "*/AlertWeb",
    modal: true
  }

};

const firebaseAPI = firebase.initializeApp({
  apiKey: "AIzaSyDgl9aTbKMdRZ9-ijSZRionh3V591gMJl4",
  authDomain: "rnmasterapp-c11e9.firebaseapp.com",
  databaseURL: "https://rnmasterapp-c11e9.firebaseio.com",
  projectId: "rnmasterapp-c11e9",
  storageBucket: "rnmasterapp-c11e9.appspot.com",
  messagingSenderId: "649592030497",
  appId: "1:649592030497:web:7728bee3f2baef208daa60",
  measurementId: "G-FYBCF3Z2W3"
});

class App extends Component {
   
  render() {

    const defaultAnalytics = firebaseAPI.analytics();
    defaultAnalytics.logEvent('APP_Loaded');
    
    return (
      <View style={{ height: '100vh' }}>
        {/* <TopNav /> */}
        {WebRoutesGenerator({ routeMap })}
        <ModalContainer />
      </View>
    );
  }
}

export default App;