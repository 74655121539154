import React from "react";
// Customizable Area Start
import {
  Grid,
  Box,
  Button,
  TextField,
  Typography,
  Container,
  Backdrop,
  CircularProgress
} from "@material-ui/core";
import {
  backgroundImage,
  logoAddax
} from "./assets";
import { withStyles } from "@material-ui/core/styles";

const StyledTextFields = withStyles({
  root: {
    color: '#0b0b0b',
    fontFamily: 'AirbnbCerealApp-Bold',
    '& .MuiOutlinedInput-input': {
      padding: '15px 15px'
    },
    '& .MuiInputBase-root': {
      borderRadius: '10px',
      marginTop: 5
    }
  }
})(TextField);

import EmailAccountLoginController from "./EmailAccountLoginController";
// Customizable Area End

class EmailAccountLoginBlock extends EmailAccountLoginController {
  
  render() {
    // Customizable Area Start
    const { email, password} = this.state?.formData;
    const { navigation } = this.props;
    const { classes }: any = this.props;
    return (
      <Container maxWidth={false} style={webStyle.container}>
        <Grid container style={webStyle.mainWrapper}>
          <Grid item xs={12} sm={8} md={6} style={webStyle.mainInnerWrapper1}>
            <Box
              style={webStyle.addaxLogo}
            >
              <Typography
                className={classes.betaText}
                style={webStyle.betaText}
                variant="h4"
                component="div"
              >
                Beta
              </Typography>
              <img
                className={classes.logo}
                style={{width: "100%"}}
                src={logoAddax}
                alt="Logo"
                height="214"
                width="313"
              />
            </Box>
          </Grid>

          <Grid item xs={12} sm={8} md={6} style={webStyle.mainInnerWrapper2}>
            <Grid item xs={10}
              style={webStyle.formContainer}
            >
              <form onSubmit={this.handleFormDataSubmit}>
                <Box style={webStyle.loginFormHeading}>
                  <Typography
                    style={webStyle.loginText}
                    variant="h6"
                  >
                    Log In
                  </Typography>
                </Box>
                <Box style={webStyle.loginFormEmail}>
                  <Typography
                    variant="subtitle1"
                  >
                    Email
                  </Typography>
                  <StyledTextFields
                    fullWidth
                    autoFocus
                    variant="outlined"
                    placeholder="Enter email"
                    type="email"
                    name="email"
                    value={email}
                    onChange={this.handleChangeFormData}
                    error={this.state?.isErrorFormData?.email ? true : false}
                  />
                  <div style={webStyle.errorMessages}>
                    {this.state?.isErrorFormData.email}
                  </div>
                </Box>
                <Box style={webStyle.loginFormPassword}>
                  <Typography
                    variant="subtitle1"
                  >
                    Password
                  </Typography>
                  <StyledTextFields
                    fullWidth
                    variant="outlined"
                    placeholder="Enter password"
                    type="password"
                    name="password"
                    value={password}
                    onChange={this.handleChangeFormData}
                    error={this.state?.isErrorFormData?.password ? true : false}

                  />
                  <div style={webStyle.errorMessages}>
                    {this.state?.isErrorFormData?.password}
                  </div>
                </Box>
                <Box style={webStyle.loginButtonBox}>
                  <Button
                    style={webStyle.loginButton}
                    fullWidth
                    variant="contained"
                    color="primary"
                    type="submit"
                  >
                    Login
                  </Button>
                </Box>
                <Box
                  style={webStyle.forgotPwd}
                  onClick={() => navigation.navigate("EmailAccountForgotPassword")}
                >
                  <Typography
                    style={webStyle.forgotPassword}
                  >
                    Forgot Password?
                  </Typography>
                </Box>
                <Box style={webStyle.termsContainer}>
                  <Typography
                    variant="subtitle2"
                  >
                    By continuing, you agree to Addax{" "}
                    <span style={webStyle.termsPrivacy}
                      onClick={() => navigation.navigate("EmailAccountTermsConditions")}
                    >
                      Terms of services
                    </span>{" "} and acknowledge you've read our{" "}
                    <span style={webStyle.termsPrivacy}
                      onClick={() => navigation.navigate("EmailAccountPrivacyPolicy")}
                    >
                      Privacy Policy
                    </span>                    
                  </Typography>
                </Box>
              </form>
            </Grid>
            {this.state?.isLoadingLogin ?
              <Backdrop
                style={{ zIndex: 1000 + 1, color: "#341f7c" }}
                open={this.state?.isLoadingLogin}
              >
                <CircularProgress color="inherit" />
              </Backdrop> : null
            }
          </Grid>
        </Grid>
      </Container>
    );
    // Customizable Area End
  }
}

// Customizable Area Start
const styles = (theme: any) => ({
  logo: {
    [theme.breakpoints.down('sm')]: {
      height: '180px',
      width: '260px',
    },
    [theme.breakpoints.down('xs')]: {
      height: '140px',
      width: '220px',
    }
  },
  betaText: {
    [theme.breakpoints.down('sm')]: {
      fontSize: '25px',
    },
    [theme.breakpoints.down('xs')]: {
      fontSize: '20px',
    }
  }
});

const webStyle = {
  container: {
    backgroundImage: `url(${backgroundImage})`,
    backgroundSize: '100% 100%',
    backgroundPosition: 'center',
  },
  mainWrapper: {
    height: '100vh',
    display: 'flex',
    justifyContent: 'center',
  },
  mainInnerWrapper1: {
    minHeight: '20vh',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
  mainInnerWrapper2: {
    minHeight: '60vh',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
  formContainer: {
    padding: '30px 40px',
    width: '440px',
    display: 'flex',
    flexDirection: 'column' as 'column',
    position: 'absolute' as 'absolute',
    borderRadius: '23px',
    background: '#ffffff',
    boxShadow: '12px 22px 24px rgba(52,31,124,0.09),-10px -8px 24px rgba(52,31,124,0.04)'
  },
  addaxLogo: {
    display: 'flex',
    flexDirection: 'row' as 'row',
    justifyContent: 'center',
    alignItems: 'baseline',
    marginRight: "20%",
  },
  betaText: {
    color: 'white',
    marginRight: "20px"
  },
  loginFormHeading: {
    paddingTop: 10,
  },
  loginText: {
    marginBottom: 5,
    fontWeight: 'bold' as 'bold',
    fontSize: "26px",
    display: 'flex',
    flexDirection: 'row' as 'row',
    justifyContent: 'flex-start',
  },
  loginFormEmail: {
    marginTop: 15,
    width: '100%',
  },
  loginFormPassword: {
    marginTop: 10,
    width: '100%',
  },
  errorMessages: {
    marginTop: 10,
    color: "red",
    display: "flex",
    flexDirection: "row" as "row",
    justifyContent: "flex-end",
    fontFamily: 'AirbnbCerealApp-Bold',
  },
  loginButtonBox: {
    marginTop: 25,
    width: '100%'
  },
  loginButton: {
    textTransform: 'capitalize' as 'capitalize',
    padding: '12px 0px',
    fontWeight: 'bold' as 'bold',
    background: '#341f7c',
    borderRadius: '10px',
  },
  forgotPwd: {
    marginTop: 15,
    marginBottom: 5,
    display: 'flex',
    flexDirection: 'row' as 'row',
    justifyContent: 'flex-end',
    alignItems: 'end',
  },
  forgotPassword: {
    textTransform: 'capitalize' as 'capitalize',
    color: '#341f7c',
    cursor: 'pointer',
    fontWeight: 'bold' as 'bold', 
  },
  termsContainer: {
    marginTop: 15,
    display: 'flex',
    flexDirection: 'row' as 'row',
    justifyContent: 'center',
    alignItems: 'center',
    textAlign: 'center' as 'center',
  },
  termsPrivacy: {
    textDecoration: "underline",
    cursor: "pointer",
    color: '#341f7c'
  }
};

export default withStyles(styles)(EmailAccountLoginBlock);
// Customizable Area End