"use strict";


const baseURL = "https://sauron-179518-ruby.b179518.dev.eastus.az.svc.builder.cafe";

Object.defineProperty(exports, "__esModule", {
  value: true
});

exports.baseURL = baseURL; 
