import React from "react";
// Customizable Area Start
import {
  Grid,
  Box,
  Typography,
  Container,
  Backdrop,
  CircularProgress
} from "@material-ui/core";
import {
  backIcon,
  profile2,
} from "./assets";
import CategoriessubcategoriesController from "./CategoriessubcategoriesController";
import CategoriesSideBar from "./CategoriesSideBar.web";
import SnackbarAlert from "../../../components/src/SnackbarAlert.web";
import ArrowForwardIosIcon from '@material-ui/icons/ArrowForwardIos';
import moment from "moment";
// Customizable Area End

export default class Events extends CategoriessubcategoriesController {
  
  // Customizable Area Start
   navigate = async (id: any) => {
    await sessionStorage.setItem('event-id', id);
    this.props.navigation.navigate("ShowEvents");
  }
  // Customizable Area End

  render() {
    // Customizable Area 
    const { drawerWidth } = this.state;
    return (
      <Grid container>
        <CategoriesSideBar
          {...this.props}
          openDrawer={this.state.openDrawer}
          onClosed={() => this.setState({ openDrawer: !this.state.openDrawer })}
          drawerWidth={drawerWidth}
        />
        <Grid item xs={12} sm={12} md={12}>
          <Container
            style={
              this.state.openDrawer ?
              {marginLeft:`${drawerWidth}px`,
              maxWidth:`calc(100% - ${drawerWidth}px)`} :
              {maxWidth:'81%'}
            }
            maxWidth="lg"
          >
            <Grid item xs={12} sm={12} md={12}
              style={webStyle.backIconTitle}
            >
              <img
                style={webStyle.backIcon}
                src={backIcon}
                alt="Back Icon"
                height="32"
                width="32"
                onClick={() => {
                  this.props.navigation.navigate("RecentArticles");
                  sessionStorage.removeItem("article-id");
                  sessionStorage.removeItem("marketplace-id");
                  sessionStorage.removeItem("event-id");
                  sessionStorage.removeItem("dossier-id");
                }}
              />
              <Typography
                style={webStyle.title}
                variant="subtitle1"
              >
                Events
              </Typography>
            </Grid>
            <Grid item xs={12} sm={12} md={12}
              style={{height: "76vh", overflow:"auto"}}
            >
              {this.state?.eventsData && this.state?.eventsData?.map((item: any) => {
                return (
                  <div key={item?.id} style={{marginLeft: "10px"}}>
                    <Box
                      style={webStyle.innerContainer}
                      onClick={() => this.navigate(item?.id)}
                    >
                      <Grid item xs={12} sm={12} md={12}>
                        <Box
                          style={webStyle.eventDateBox}
                        >
                          <Typography
                            style={webStyle.titleHeadingEventDate}
                          >
                            Event Date
                          </Typography>
                          <Typography
                            style={webStyle.titleHeading}
                          >
                            {item?.fields?.Date_of_Event ? moment(item?.fields?.Date_of_Event).format("DD MMM") : null}{" "}
                            {item?.fields?.Date_of_Event ? moment(item?.fields?.Date_of_Event).format("YYYY") : null}
                          </Typography>
                        </Box>
                        <Box style={{ display: "contents" }}>
                          <Typography
                            style={webStyle.titleHeading}
                            variant="subtitle1"
                          >
                            {item?.fields?.Name_of_Event ? item?.fields?.Name_of_Event : null}
                          </Typography>
                          <Typography
                            style={webStyle.subtitle}
                            variant="subtitle2"
                          >
                            {item?.fields?.Write_up_for_Event_Awareness_Email ?
                            item?.fields?.Write_up_for_Event_Awareness_Email.substring(0, 250) :
                            null}...
                            &nbsp;
                          </Typography>
                        </Box>
                      </Grid>
                      <ArrowForwardIosIcon style={{marginLeft: 10}} />
                    </Box>
                    <hr style={webStyle.hrTag} />
                  </div>
                );
              })}
            </Grid>
          </Container>
        </Grid>
        {this.state?.eventsLoading ?
          <Backdrop
            style={{ zIndex: 1000 + 1, color: "#341f7c" }}
            open={this.state?.eventsLoading}
          >
            <CircularProgress color="inherit" />
          </Backdrop> : null
        }
        {this.state?.tokenExpired === 'Token has Expired' ?
          <SnackbarAlert
            openSnackbar={this.state?.openSnackbar}
            handleCloseSnackbar={this.handleCloseSnackbar}
          />
          : null
        }
      </Grid>
    );
    // Customizable Area End
  }
}

// Customizable Area Start
const webStyle = {
  backIconTitle: {
    marginTop: "100px",
    marginBottom: 5,
    display: "flex",
    flexDirection: "row" as "row",
    justifyContent: "flex-start",
    alignItems: "center",
  },
  backIcon: {
    cursor: 'pointer'
  },
  title: {
    fontSize: "24px",
    fontWeight: 'bold' as 'bold',
    color: '#341f7c',
    margin: "auto"
  },
  innerContainer: {
    marginTop: 20,
    width: "95%", 
    display: 'flex',
    flexDirection: 'row' as 'row',
    justifyContent: 'center',
    alignItems: 'center',
    cursor: 'pointer',
  },
  eventDateBox: {
    float: "left" as "left",
    maxWidth: "100%",
    marginBottom: "10px",
    height: "140px",
    width: "205px",
    marginRight: "20px",
    borderRadius: "10px",
    borderLeft: "8px solid #341f7c",
    padding: "10px",
    display: "flex",
    flexDirection: "column" as "column",
    justifyContent: "space-evenly",
    alignItems: "center"
  },
  titleHeadingEventDate: {
    fontSize: "20px",
    fontWeight: 'bold' as 'bold',
    lineHeight: "27px",
    opacity: "0.5"
  },
  titleHeading: {
    fontSize: "20px",
    fontWeight: 'bold' as 'bold',
    lineHeight: "27px",
  },
  subtitle: {
    fontSize: "18px",
    fontWeight: 400,
    marginTop: 10
  },
  readMore: {
    color: "#341f7c",
    fontWeight: "bold" as "bold",
  },
  hrTag: {
    width: '95%',
    border: "0.5px solid #341f7c",
    opacity: "0.08475167"
  },
};
// Customizable Area End