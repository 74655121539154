import React from "react";
// Customizable Area Start
import {
  Grid,
  Box,
  Button,
  TextField,
  Typography,
  Container,
  Backdrop,
  CircularProgress
} from "@material-ui/core";
import {
  backgroundImage,
  logoAddax
} from "./assets";
import OtpInput from "react-otp-input";
import { withStyles } from "@material-ui/core/styles";

const StyledTextFields = withStyles({
  root: {
    color: '#0b0b0b',
    fontFamily: 'AirbnbCerealApp-Bold',
    '& .MuiOutlinedInput-input': {
      padding: '15px 15px'
    },
    '& .MuiInputBase-root': {
      borderRadius: '10px',
      marginTop: 5
    }
  }
})(TextField);

import EmailAccountLoginController from "./EmailAccountLoginController";
// Customizable Area End

class EmailAccountForgotPassword extends EmailAccountLoginController {
  
  render() {
    // Customizable Area Start
    const { forgotEmail } = this.state;
    const { classes }: any = this.props;
    return (
      <Container maxWidth={false} style={webStyle.container}>
        <Grid container style={webStyle.mainWrapper}>
          <Grid item xs={12} sm={8} md={6} style={webStyle.mainInnerWrapper1}>
            <Box
              style={webStyle.addaxLogo}
            >
              <Typography
                className={classes.betaText}
                style={webStyle.betaText}
                variant="h4"
                component="div"
              >
                Beta
              </Typography>
              <img
                className={classes.logo}
                style={{width: '100%'}}
                src={logoAddax}
                alt="Logo"
                height="214"
                width="313"
              />
            </Box>
          </Grid>
          {!this.state?.otp ?
            <Grid item xs={12} sm={8} md={6} style={webStyle.mainInnerWrapper2}>
              <Grid item xs={10}
                style={webStyle.formContainerEmail}
              >
                <form onSubmit={this.handleForgotEmailSubmit}>
                  <Box style={webStyle.loginFormHeading}>
                    <Typography
                      style={webStyle.forgotPasswordEmail}
                      variant="h6"
                    >
                      Forgot Password
                    </Typography>
                    <Typography
                      style={webStyle.pleaseEnter}
                      variant="body1"
                    >
                      Please enter your email to get an OTP!
                    </Typography>
                  </Box>
                  <Box style={webStyle.loginFormEmail}>
                    <Typography
                      variant="subtitle1"
                    >
                      Email
                    </Typography>
                    <StyledTextFields
                      fullWidth
                      variant="outlined"
                      placeholder="Enter email"
                      type="email"
                      name="forgotEmail"
                      value={forgotEmail}
                      onChange={this.handleForgotEmailChange}
                      error={this.state?.isErrorForgotEmail?.forgotEmail ? true : false}
                    />
                    <div style={webStyle.errorMessage}>
                      {this.state?.isErrorForgotEmail?.forgotEmail}
                    </div>
                  </Box>
                  <Box style={webStyle.getOtpButtonBox}>
                    <Button
                      style={webStyle.getOtpButton}
                      fullWidth
                      variant="contained"
                      color="primary"
                      type="submit"
                    >
                      Get OTP
                    </Button>
                  </Box>
                </form>
              </Grid>
              {this.state?.forgotEmailLoading ?
                <Backdrop
                  style={{ zIndex: 1000 + 1, color: "#341f7c" }}
                  open={this.state?.forgotEmailLoading}
                >
                  <CircularProgress color="inherit" />
                </Backdrop> : null
              }
            </Grid>
          :
            <Grid item xs={12} sm={8} md={6} style={webStyle.mainInnerWrapper2}> 
              <Grid item xs={10}
                style={webStyle.formContainerOtp}
              >
                <form onSubmit={this.handleOtpSubmit}>
                  <Box style={webStyle.loginFormHeading}>
                    <Typography
                      style={webStyle.forgotPasswordText}
                      variant="h6"
                    >
                      Forgot Password?
                    </Typography>
                    <Typography
                      style={webStyle.enterOtpText}
                      variant="subtitle1"
                    >
                      Enter the OTP code you received in{" "}
                      {this.state?.forgotEmail} to reset your password
                    </Typography>
                  </Box>
                  <Box style={webStyle.otpFieldsBox}>
                    <OtpInput
                      inputStyle={webStyle.otpInputs}
                      value={this.state?.otpInput}
                      onChange={this.otpHandleChange}
                      numInputs={4}
                      isInputNum={true}
                      shouldAutoFocus={true}
                    />
                  </Box>
                  <div style={webStyle.errorOtp}>
                    {this.state?.isErrorOtp?.otpInput}
                  </div>
                  <Box style={webStyle.resendCodeBox}>
                    <Typography
                      variant="subtitle1"
                    >
                      didn't get the code?{" "}
                      <span>
                        <Typography
                          style={webStyle.resendCode}
                          onClick={this.forgotPasswordEmail}
                        >
                          Resend Code
                        </Typography>
                      </span>
                    </Typography>
                  </Box>
                  <Box style={webStyle.buttons}>
                    <Typography
                      style={webStyle.cancelButtonOtp}
                      onClick={() => this.setState({ otp: false })}
                    >
                      Cancel
                    </Typography>
                    <Button
                      style={webStyle.submitButtonOtp}
                      variant="contained"
                      color="primary"
                      type="submit"
                    >
                      Submit
                    </Button>
                  </Box>
                </form>
              </Grid>
              {this.state?.otpLoading ?
                <Backdrop
                  style={{ zIndex: 1000 + 1, color: "#341f7c" }}
                  open={this.state?.otpLoading}
                >
                  <CircularProgress color="inherit" />
                </Backdrop> : null
              }
            </Grid>
          }
        </Grid>
      </Container>
    );
    // Customizable Area End
  }
}

// Customizable Area Start
const styles = (theme: any) => ({
  logo: {
    [theme.breakpoints.down('sm')]: {
      height: '180px',
      width: '260px',
    },
    [theme.breakpoints.down('xs')]: {
      height: '140px',
      width: '220px',
    }
  },
  betaText: {
    [theme.breakpoints.down('sm')]: {
      fontSize: '25px',
    },
    [theme.breakpoints.down('xs')]: {
      fontSize: '20px',
    }
  }
});

const webStyle = {
  container: {
    backgroundImage: `url(${backgroundImage})`,
    backgroundSize: '100% 100%',
    backgroundPosition: 'center',
  },
  mainWrapper: {
    height: '100vh',
    display: 'flex',
    justifyContent: 'center',
  },
  betaText: {
    color: 'white',
    marginRight: "20px"
  },
  mainInnerWrapper1: {
    minHeight: '20vh',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
  mainInnerWrapper2: {
    minHeight: '60vh',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
  addaxLogo: {
    display: 'flex',
    flexDirection: 'row' as 'row',
    justifyContent: 'center',
    alignItems: 'baseline',
    marginRight: "20%"
  },
  formContainerOtp: {
    padding: '40px 40px',
    width: '362px',
    display: 'flex',
    flexDirection: 'column' as 'column',
    position: 'absolute' as 'absolute',
    borderRadius: '23px',
    background: '#ffffff',
    boxShadow: '12px 22px 24px rgba(52,31,124,0.09),-10px -8px 24px rgba(52,31,124,0.04)'
  },
  formContainerEmail: {
    padding: '40px 40px',
    width: '440px',
    display: 'flex',
    flexDirection: 'column' as 'column',
    position: 'absolute' as 'absolute',
    borderRadius: '23px',
    background: '#ffffff',
    boxShadow: '12px 22px 24px rgba(52,31,124,0.09),-10px -8px 24px rgba(52,31,124,0.04)'
  },
  loginFormHeading: {
    paddingTop: 10,
  },
  forgotPasswordEmail: {
    marginBottom: 5,
    fontWeight: 'bold' as 'bold',
    fontSize: "24px",
    display: 'flex',
    flexDirection: 'row' as 'row',
    justifyContent: 'flex-start',
  },
  pleaseEnter: {
    display: 'flex',
    flexDirection: 'row' as 'row',
    justifyContent: 'flex-start'
  },
  forgotPasswordText: {
    display: 'flex',
    fontWeight: 'bold' as 'bold',
    fontSize: "24px",
    flexDirection: 'row' as 'row',
    justifyContent: 'center',
  },
  enterOtpText: {
    color: '#90959b',
    fontSize: '14px',
    marginTop: 15,
    display: 'flex',
    flexDirection: 'row' as 'row',
    justifyContent: 'center',
    alignItems: "center",
    textAlign: 'center' as 'center',
  },
  otpFieldsBox: {
    p: 1,
    marginTop: 25,
    display: "flex",
    flexDirection: "row" as "row",
    justifyContent: "space-evenly",
    alignItems: "center",
    color: '#5b5b5b'
  },
  otpInputs: {
    height: "45px",
    width: "45px",
    fontSize: "18px",
    border: "1px solid white",
    marginRight: 12,
    marginLeft: 12,
    borderRadius: "8px",
    boxShadow: '1px 1px 5px grey'
  },
  loginFormEmail: {
    marginTop: 15,
    width: '100%',
  },
  errorMessage: {
    marginTop: 10,
    marginBottom: 15,
    color: "red",
    float: "right" as "right",
    fontFamily: 'AirbnbCerealApp-Bold',
  },
  getOtpButtonBox: {
    marginTop: 25,
    width: '100%'
  },
  getOtpButton: {
    textTransform: 'capitalize' as 'capitalize',
    padding: '12px 0px',
    fontWeight: 'bold' as 'bold',
    background: '#341f7c',
    borderRadius: '10px',
  },
  buttons: {
    marginTop: 30,
    display: 'flex',
    flexDirection: 'row' as 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
  },
  cancelButtonOtp: {
    textTransform: 'capitalize' as 'capitalize',
    fontWeight: 'bold' as 'bold',
    color: '#341f7c',
    cursor: 'pointer',
    marginLeft: 10
  },
  submitButtonOtp: {
    textTransform: 'capitalize' as 'capitalize',
    padding: '8px 0px',
    fontWeight: 400,
    background: '#341f7c',
    borderRadius: '30px',
    width: '180px',
    fontSize: '16px',
  },
  errorOtp: {
    marginTop: 15,
    color: "red",
    textAlign: "center" as "center",
    fontFamily: 'AirbnbCerealApp-Bold',
  },
  resendCodeBox: {
    marginTop: 20,
    display: 'flex',
    flexDirection: 'row' as 'row',
    justifyContent: 'center',
    alignItems: 'center',
  },
  resendCode: {
    display: 'inline-block',
    textTransform: 'capitalize' as 'capitalize',
    color: '#341f7c',
    fontWeight: 'bold' as 'bold',
    cursor: 'pointer'
  },
};

export default withStyles(styles)(EmailAccountForgotPassword);
// Customizable Area End